import React from 'react';
import PropTypes from 'prop-types';
import { schemaService } from '../../../domain/services/schemaService';

export const VideoSchema = ({ schemaData }) => {
  return schemaData ? (
    <script
      type="application/ld+json" // eslint-disable-next-line
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(
          schemaService.getStandaloneVideoSchema(schemaData)
        )
      }}
    />
  ) : null;
};

VideoSchema.propTypes = {
  schemaData: PropTypes.objectOf(PropTypes.any).isRequired
};
